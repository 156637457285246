
import { defineComponent } from 'vue';
import ProfileForm from '@/views/new-design/pages/Profile/OverviewForm.vue';
import LinkMobile from '@/components/modals/forms/LinkMobile.vue';
import {
  Getters as AuthGetters,
  Actions as AuthActions,
  Mutations as AuthMutations,
} from '@/store/enums/AuthEnums';
import { mapGetters, mapActions, mapState } from 'vuex';
import {
  Actions as UserActions,
  Getters as UserGetters,
} from '@/store/enums/UserEnums';
import toasts from '@/utils/toasts';
import parser from '@/utils/parser';
import { dateFormatter } from '@/core/utils/PayloadSanitized';
import {
  Actions as SystemActions,
  Getters as SystemGetters,
} from '@/store/enums/SystemEnums';
import mask from '@/utils/mask';
import text from '@/utils/text';

export default defineComponent({
  name: 'profile-overview',
  data: () => ({
    isEdit: false,
    loading: false,
    sendCodeMessage: '',
  }),
  components: {
    ProfileForm,
    LinkMobile,
  },
  mounted() {
    this.listAllCountries();

    this.handleListStates();
  },
  computed: {
    ...mapGetters({
      authUser: AuthGetters.GET_AUTH_USER,
      errors: AuthGetters.GET_AUTH_USER_ACTION_ERROR,
      isMobileVerified: AuthGetters.GET_MOBILE_IS_VERIFIED,
    }),
    countryName() {
      return parser.country(this.authUser.country);
    },
    stateName() {
      return parser.state(this.authUser.state);
    },
    mobileNumber() {
      return text.formatMobile(this.authUser.mobile_number);
    },
  },
  methods: {
    ...mapActions({
      sendcode: AuthActions.SEND_MOBILE_CODE,
      verify: AuthActions.VERIFIY_MOBILE,
      listAllCountries: SystemActions.LIST_ALL_COUNTRIES,
      listStates: SystemActions.LIST_STATES,
    }),
    handleListStates() {
      this.listStates(this.authUser.country);
    },
    handleEdit(value) {
      this.isEdit = value;
    },
    sendCode() {
      const params = {
        mobile_number: this.authUser.mobile_number,
      };

      this.sendcode(params)
        .then(() => {
          this.sendCodeMessage =
            'Verification code successfully sent to your mobile number';
        })
        .catch(() => {
          const { errors, message } = this.errors;
          toasts.error(errors, message);
        });
    },
    handleVerify(values) {
      if (this.loading) return;
      this.loading = true;

      this.verify(values)
        .then(() => {
          this.isEdit = false;
          (this.$refs.verificationForm as any).resetForm();
          toasts.success('Mobile number successfully verified.');
          this.loading = false;
        })
        .catch(() => {
          const { errors, message } = this.errors;
          toasts.error(errors, message);
          this.loading = false;
        });
    },
    handleFormSubmitted() {
      this.isEdit = false;
    },
    formatDate(date, format) {
      return dateFormatter(date, format);
    },
  },
});
